
 <main class="admin-main">
   <!-- style="background-color: #f5f5f5" -->
   <div class="d-md-flex p-h-40 justify-content-center mb-5">
     <div class="text-center">
       <img src="assets/images/logo/cowLogo.png" class="p-3 imgLogo" style="width: 25%" alt="" />
     </div>
   </div>
   <div class="container" style="padding-top: 20px;">
      <h1 style="text-align: center;">TERMS OF USE</h1>
      <div class="headStyle">
        <h2>1. Acceptance of Terms of Use Agreement.</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          1.1. By creating a Connected Cow account on a mobile device, mobile
          application or computer (collectively, the "Service"), you agree to be
          bound by all terms communicated to you and accepted by you
          (collectively, this "Agreement"). If you do not agree and consent to
          be bound by all terms of this Agreement, please do not use the
          Service.
        </h4>
        <h4>
          1.2. We may make changes to this Agreement and the Service from time
          to time. We may do so for a variety of reasons, such as to reflect
          changes in the law or requirements, new features, or changes in
          business practices. The most current version of this Agreement will be
          posted and you should check the most current version regularly. The
          most current version is the version that applies. If the changes are
          material changes that affect your rights or obligations, we will
          (unless prevented by applicable law) notify you at least 30 days in
          advance of the changes by appropriate means, including notice through
          the Service or by email. If you continue to use the Service after the
          changes become effective, you will be deemed to have accepted the
          amended Agreement.
        </h4>
      </div>

      <div class="headStyle">
        <h2>2. Your Account.</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          2.1. You can use several login methods to use Connected Cow, including
          your Facebook account. If you choose to use your Facebook account, you
          authorise us to access and use certain Facebook account information,
          including but not limited to your public Facebook profile.
        </h4>
        <h4>
          2.2. You are responsible for maintaining the confidentiality of your
          login credentials that you use when registering with Connected Cow. You
          are also solely responsible for all activities that occur under these
          credentials. If you believe that someone has gained access to your
          account, please contact us immediately.
        </h4>
      </div>

      <div class="headStyle">
        <h2>3. Service Arrangement and Termination</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          3.1. Connected Cow always endeavours to improve the Service and provide
          you with additional features that you will find attractive and useful.
          This means that from time to time we may add new product features or
          improvements, as well as remove some features, and unless these
          actions materially affect your rights or obligations, we may not
          notify you before taking them. We may even discontinue the Service
          altogether, in which case we will notify you in advance, except in
          circumstances that may prevent us from notifying you, such as security
          concerns.
        </h4>
        <h4>
          3.2. You may terminate your account at any time, for any reason.
          However, if you use a third party payment account, such as the Apple
          App Store or Google Play Store, you will need to manage in-app
          purchases through such an account to avoid additional invoices. If
          Connected Cow believes that you have breached this Agreement, it may
          terminate your account at any time. In the event of such termination,
          there will be no refund for your purchases.
        </h4>
      </div>

      <div class="headStyle">
        <h2>4. Rights Granted to You by Connected Cow</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          4.1. Connected Cow grants you an exclusive, worldwide, royalty-free,
          non-transferable, non-exclusive, revocable, revocable, and
          non-sublicensable licence to access and use the Service. The sole
          purpose of this licence is for you to use and enjoy the benefits of
          the Service as intended by Connected Cow and provided by this
          Agreement. This licence and authorisation to access the Service is
          automatically revoked if you do any of the following:
        </h4>
        <ul>
          <li>
            <h4>
              Use the Service or any content on the Service for any commercial
              purpose without our written consent.
            </h4>
          </li>

          <li>
            <h4>
              Copy, modify, transmit, create derivative works from, use or
              reproduce any copyrighted material, image, trademark, trade name,
              service mark or other intellectual property, content or
              proprietary information accessible through the Service in any way
              without the prior written consent of Connected Cow
            </h4>
          </li>

          <li>
            <h4>
              Explain or imply that any of your statements are approved by
              Connected Cow.
            </h4>
          </li>
          <li>
            <h4>
              Use any robot, bot, spider, crawler, scraper, site
              search/retrieval application, proxy or other manual or automated
              device, method or process to access, retrieve, index, "data mine",
              or in any way mimic or circumvent the navigation structure or
              presentation of the Service or its contents.
            </h4>
          </li>
          <li>
            <h4>
              Use the Service or servers or networks connected to the Service in
              a manner that interferes with, disrupts or adversely affects the
              Service.
            </h4>
          </li>
          <li>
            <h4>
              Install viruses or other malware or compromise the security of the
              Service.
            </h4>
          </li>
          <li>
            <h4>
              Forge headers or otherwise manipulate identifiers to disguise the
              origin of any information transmitted to or through the Service.
            </h4>
          </li>
          <li>
            <h4>
              "frame" or "mirror" any portion of the Service without the prior
              written consent of Connected Cow.
            </h4>
          </li>
          <li>
            <h4>
              Use meta tags, codes or other devices that contain any reference
              (to any trademark, trade name, service mark, logo or slogan) to
              direct any person to any other website for any purpose.
            </h4>
          </li>
          <li>
            <h4>
              modify, adapt, sublicense, sublicense, translate, sell, reverse
              engineer, decrypt, disassemble, decompile or otherwise disassemble
              any part of the Service or cause others to do so.
            </h4>
          </li>
          <li>
            <h4>
              Use or develop third-party applications to interact with the
              Service or other members' Content or information without our
              written consent.
            </h4>
          </li>
          <li>
            <h4>
              Use, access, or publish the programming interface of the
              Connected Cow application without our written consent.
            </h4>
          </li>
          <li>
            <h4>
              Investigate, scan or test the vulnerabilities of our Service or
              any system or network.
            </h4>
          </li>
          <li>
            <h4>
              Encourage or support any activity in violation of this Agreement.
            </h4>
          </li>
        </ul>
        Connected Cow may investigate any illegal or unauthorised use of the
        Service and take appropriate legal action, including termination of your
        account.

        <h4>
          4.2. Any software we provide to you may automatically download and
          install upgrades, updates or other new features. You can set these
          automatic downloads in your device's settings.
        </h4>
      </div>

      <div class="headStyle">
        <h2>5. Rights You Grant to Connected Cow</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          5.1. By creating an account, you grant Connected Cow a worldwide,
          transferable, sub-licensable, royalty-free, transferable,
          sublicensable, royalty-free right and licence to host, store, use,
          copy, display, reproduce, adapt, edit, publish, modify and distribute
          information that you share, upload, display or mark as visible on the
          Service (collectively, "posts") or submit to other members
          (collectively, "Content"), as well as content that you allow us to
          access through third parties such as Facebook. Connected Cow's licence
          for Your Content will be a non-exclusive licence, but Connected Cow's
          licence in respect of secondary works created through use of the
          Service will be exclusive. For example, Connected Cow will have an
          exclusive licence for screenshots of the Service containing Your
          Content. In addition, you authorise Connected Cow to act on your behalf
          with respect to infringing uses of your Content obtained through the
          Service by other members or third parties, so that Connected Cow can
          prevent the use of your Content outside of the Service. Our licence to
          your Content is subject to your rights under applicable laws (such as
          laws relating to personal data protection for any Content that
          contains your personal information as defined by such laws) and is for
          the limited purposes of operating, developing, providing, and
          improving the Service and researching and developing new services. Any
          Content you place or authorise us to place on the Service may be
          viewed by other members and may be viewed by any person who visits or
          participates in the Service.
        </h4>
        <h4>
          5.2. You agree that all information you submit upon creation of your
          account, including information submitted from your Facebook account,
          is true and accurate and that you have the right to publish Content
          through the Service and to grant Connected Cow the above licence.
        </h4>
        <h4>
          5.3.You understand and agree that we may monitor or review any Content
          you post as part of the Service. We may delete, in whole or in part,
          any Content that, in our sole judgement, is in breach of this
          Agreement or may damage the reputation of the Service.
        </h4>
        <h4>
          5.4. You agree to be respectful and courteous when communicating with
          our customer service representatives. We reserve the right to
          terminate your account immediately if at any time we feel that your
          behaviour towards any of our customer service representatives or other
          employees is threatening, harassing or offensive.
        </h4>
        <h4>
          5.5. In return for Connected Cow enabling you to use the Service, you
          agree that we, our affiliates and third party partners may place
          advertisements on the Service. By sending recommendations or feedback
          to Connected Cow about our Service, you agree that Connected Cow may use
          and share this feedback for any purpose without compensation to you.
        </h4>
        <h4>
          5.6. You agree that Connected Cow may access, retain and disclose your
          account information when required by law, for the fulfilment of the
          agreement with you or when it is believed in good faith that access,
          retention or disclosure is necessary, such as in the following cases:
          (i) comply with legal process; (ii) honour this Agreement; (iii)
          respond to claims that any Content violates the rights of third
          parties; (iv) respond to your customer service requests; or (v)
          protect the rights, property, or personal safety of Company or any
          other person.
        </h4>
      </div>

      <div class="headStyle">
        <h2>6. Community Rules.</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          6.1. By using the Service, you agree that you will not Use the Service
          for any purpose that is unlawful or prohibited by this Agreement. use
          the Service for any harmful or abusive purpose Using the Service for
          the purpose of harming Connected Cow
        </h4>

        <h4>
          6.2. If you have breached this Agreement, misused the Service in any
          way, including your actions and communications on or off the Service,
          or behaved in any way that Connected Cow deems inappropriate or
          illegal, Connected Cow reserves the right to investigate you and/or
          terminate your account without refund for any purchases you have made.
          Your authorisation to use the Service will be automatically cancelled.
        </h4>
      </div>

      <div class="headStyle">
        <h2>7. Purchases.</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          7.1. In general, Connected Cow may from time to time offer products and
          services for sale ("in-app purchases") through the App Store, Google
          Play Store, operator billing, direct billing by Connected Cow or
          purchase through other payment platforms authorised by Connected Cow.
          If you choose to make an in-app purchase, you will be asked to verify
          your purchase with the appropriate payment provider and your payment
          method (which may be your card or a third party account such as Google
          Play Store or App Store) (your "Payment Method") will reflect the
          prices of the in-app purchase service(s) you have selected, as well as
          any fees shown to you for sales data and other taxes that may be
          applied to your payment, and you authorise Connected Cow or the
          intermediary third party account, if any, to charge these fees.
        </h4>
        <h4>
          7.2. Automatic Renewal; Automatic Card Payment
          <br><br>
          7.2.1.If you purchase an automatically renewing periodic subscription
          through an in-app purchase, your Payment Method will be billed for the
          monthly subscription on an ongoing basis until you cancel. After your
          initial subscription start period and again after the subsequent
          subscription period, your subscription will be automatically extended
          at the price you agreed to when subscribing for a period equal in
          duration to the previous one. Your card payment details will be stored
          in accordance with the Agreement and subsequently used for automatic
          card payments.

          <br><br>7.2.2.Objections to a payment already made must be
          reported directly by Connected Cow or, if you have been billed by a
          relevant third party account such as the App Store, to 0 312 911 07
          76. You can also dispute by contacting your bank or payment provider,
          who can give you more information about your rights as well as the
          applicable time limits. You can unconditionally revoke your consent to
          automatic payment by card at any time by going to the settings of
          Connected Cow or the relevant third-party account, but please note that
          you are still obliged to pay any outstanding debts. You can click on
          the link to get information about Monthly Subscription Prices. These
          prices may vary. It should be followed from the link up to date.
          <br><br>7.2.3.If you wish to change or terminate your
          subscription, even if you have deleted your account with us or the
          Connected Cow app on your device, you must log in to the third party
          account and follow the instructions to terminate or cancel your
          subscription. Deleting your Connected Cow account or the Connected Cow
          app from your device will not terminate or cancel your subscription;
          Connected Cow will retain all amounts charged to your Payment Method
          until you terminate or cancel your subscription on Connected Cow or in
          your third party account. If you terminate or cancel your
          subscription, you may use your subscription until the end of the
          then-current subscription period and your subscription will not renew
          after the then-current period. <br><br>7.2.4. Additional Terms as
          applicable if you pay Connected Cow directly with your Payment Method.
          If you pay Connected Cow directly, Connected Cow may correct any billing
          errors even if Connected Cow has already requested or received a
          payment. If you initiate a chargeback or reverse a payment transaction
          by contacting your Payment Method, Connected Cow may terminate your
          account immediately at its sole discretion. <br><br>7.2.5.
          Refunds. In general, all fees paid for purchases are non-refundable
          and no refunds or credits will be given for partially used periods. We
          may make an exception if a refund request is made within fourteen days
          of the transaction date for a subscription option or if the law in
          your jurisdiction provides for a refund. <br><br>7.2.6.To request
          a refund: <br><br>7.2.7.If you made a purchase using your Apple
          ID, refunds will be handled by Apple and not Connected Cow. To request
          a refund, go to the App Store, click on your Apple ID, select
          "Purchase history", find the transaction and click "Report a Problem".
          You can also submit a request at https://getsupport.apple.com

          <br><br>7.2.8.If you have made a purchase using your Google Play
          Store account or directly through Connected Cow : Please contact
          customer support (0 312 911 07 76) with your Google Play Store order
          number (which you can find in your confirmation email or by logging in
          to Google Wallet) or your Connected Cow order number (which you can
          find in your confirmation email). <br><br>7.2.9.If you exercise
          your right to cancel (except for purchases made through an Apple ID
          controlled by Apple), we will refund (or request Google to refund) all
          payments we have received from you without undue delay and in any
          event within 14 days of receiving notice of your decision to cancel
          the Agreement. We will make such refund to the payment method
          originally used by you. In any case, you will not be charged for the
          refund.

          <br><br>7.3.0.If you have made a purchase through a payment
          platform not listed above, please contact the third party seller
          directly from whom you made your purchase to request a refund.
          <br><br>7.3.1.Pricing. Connected Cow operates a global business and
          our pricing policy varies based on a variety of factors. We often
          offer promotional pricing, which may vary by region, length of
          subscription, package size, and other variables. We also regularly
          test new features and payment options.
        </h4>
      </div>

      <div class="headStyle">
        <h2>8. Denial of Liability.</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          8.1. Connected Cow PROVIDES THE SERVICE "AS IS" AND "AS AVAILABLE" AND
          IN RELATION TO THE SERVICE (INCLUDING ALL CONTENT CONTAINED THEREIN)
          TO THE EXTENT PERMITTED BY GOVERNING LAWS, NO WARRANTIES OF ANY KIND,
          EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
          LIMITATION, WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. Connected Cow
          does not warrant that (A) the Service will be uninterrupted, secure or
          error-free, or (B) any defects or errors in the Service will be
          corrected.
        </h4>
      </div>
      <div class="headStyle">
        <h2>9. Third Party Services.</h2>
      </div>
      <div class="bodyStyle">
        <h4>
          9.1. The Service may contain advertisements and promotions offered by
          third parties and links to other websites or resources. Connected Cow
          is not responsible for the availability (or unavailability) of these
          external websites or resources. If you choose to interact with third
          parties made available through our Service, their relationship with
          you will be governed by that party's terms. Connected Cow is not
          responsible for the terms or actions of such third parties.
        </h4>
      </div>
      <div class="headStyle">
        <h2>10. Conclusion</h2>
      </div>
      <div class="bodyStyle marginBottom">
        <h4>
          This Agreement constitutes the entire agreement between you and
          Connected Cow with respect to the use of the Service. If any provision
          of this Agreement is held invalid, the remainder of this Agreement
          shall remain in full force and effect. The Company's failure to
          enforce any right or provision of this Agreement will not constitute a
          waiver of such right or provision. You agree that your Connected Cow
          account is not transferable and that all of your rights in and to your
          account and its Content will terminate upon your death. No agency,
          partnership, joint venture, joint venture, fiduciary or other special
          relationship or employment is created as a result of this Agreement,
          and you may not make representations on behalf of or bind Connected Cow
          in any way.
        </h4>
      </div>
    </div>
  <br />
  <br />  
 </main>