// External Imports
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ConfirmationDialogComponent } from './ConfirmationDialog';

@Injectable()
export class DialogServices {
  constructor(private modalService: NzModalService) {}
  //hold defalut configuration of dialog
  private dialogConfiguration = {
    nzWidth: '700px',
    nzCentered: false,
    nzMaskClosable: false,
    nzClosable: true,
    nzComponentParams: {
      value: 'Template Context',
    },
  };

  /**
   * This method would configure the dialog and not configure set default configuration
   * @param config Configuration of dialog
   */
  initializeConfiguation(config: any) {
    if (!config) {
      config = this.dialogConfiguration;
    } else {
      // Iterate and input unassigned configuration from default
      for (let key in this.dialogConfiguration) {
        if (config[key] === undefined || config[key] === null) {
          config[key] = this.dialogConfiguration[key];
        }
      }
    }
    return config;
  }
  /**
   * This is used to open custom dialog
   * @param component compoment that need to be paased
   * @param dialogData for passing dynamic data
   * @param config dialog configuration
   */
  public openTplModel(
    tplTitle: TemplateRef<{}>,
    tplContent: TemplateRef<{}>,
    tplFooter: TemplateRef<{}>,
    config?: any
  ): void {
    try {
      config = this.initializeConfiguation(config);

      this.modalService.create({
        nzTitle: tplTitle,
        nzContent: tplContent,
        nzFooter: tplFooter,
        nzMaskClosable: false,
        nzClosable: false,
        nzComponentParams: {
          value: 'Template Context',
        },
        nzOnOk: () => {},
      });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * This is used to open custom dialog
   * @param component compoment that need to be paased
   * @param dialogData for passing dynamic data
   * @param config dialog configuration
   */
  public distoyTplModel(modelRef: NzModalRef): void {
    try {
      modelRef.destroy();
    } catch (e) {
      console.log(e);
    }
  }

  public createComponentModal(
    title: any,
    component: any,
    data: any = null,
    width: number = 700,
    containerRef: ViewContainerRef = null
  ) {
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: component,
      nzWidth: width,
      nzViewContainerRef: containerRef,
      nzComponentParams: {
        data: data,
      },
      nzOnOk: () => new Promise((resolve) => setTimeout(resolve, 1000)),
      nzFooter: [],
    });
    modal.componentInstance.data = data;

    return modal;
  }

  public createConfirmationModal(
    title: any,
    data: any,
    width: number = 500,
    containerRef: ViewContainerRef = null
  ) {
    const modal = this.modalService.create({
      nzTitle: `Are you sure you want to delete ${title} ?`,
      nzContent: ConfirmationDialogComponent,
      nzWidth: 720,
      nzViewContainerRef: containerRef,
      nzComponentParams: {
        data: data,
      },
      nzOnOk: () => new Promise((resolve) => setTimeout(resolve, 1000)),
      nzFooter: [],
    });
    modal.componentInstance.data = data;

    return modal;
  }

  public createConfirmationModal1(
    title: any,
    data: any,
    width: number = 500,
    containerRef: ViewContainerRef = null
  ) {
    const modal = this.modalService.create({
      nzTitle: `Are you sure you want to Acknowledged ${title} ?`,
      nzContent: ConfirmationDialogComponent,
      nzWidth: 720,
      nzViewContainerRef: containerRef,
      nzComponentParams: {
        data: data,
      },
      nzOnOk: () => new Promise((resolve) => setTimeout(resolve, 1000)),
      nzFooter: [],
    });
    modal.componentInstance.data = data;

    return modal;
  }

  

}
