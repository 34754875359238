import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
  {
    path: '/',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: [],
    disabled: false,
    url: '',
    module: 1,
  },
  {
    path: '',
    title: 'Livestock Management',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'database',
    disabled: false,
    url: '',
    submenu: [
      {
        path: '',
        title: 'Cattle Management',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'control',
        disabled: false,
        url: '',
        submenu: [
          {
            path: 'animal',
            title: 'List Animals',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'inactive-devices',
            title: 'Inactive Devices',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'exclamation',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'geofence-location',
            title: 'Alerts',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'alert',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'repeat-breeder',
            title: 'Repeat Breeder',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'merge-cells',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'infertility',
            title: 'Infertility Report',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'ungroup',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'cowmaps',
            title: 'Map Animals',
            icon: 'database',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'animal/newCalf',
            title: 'Add Animal',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'plus-circle',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'animal/identify-cattle',
            title: 'Biometric Identification',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'left',
            submenu: [],
            disabled: false,
            url: '',
          },
          // {
          //   path: 'animal/newAnimal',
          //   title: 'New Animal (purchase)',
          //   iconType: 'nzIcon',
          //   iconTheme: 'outline',
          //   icon: 'plus-circle',
          //   submenu: [],
          //   disabled: false,
          //   url: '',
          // },
        ],
      },

      {
        path: '',
        title: 'Health Management',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'medicine-box',
        disabled: false,
        url: '',
        submenu: [
          {
            path: 'health',
            title: 'Examination',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'medicine-box',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'health/vaccine',
            title: 'Vaccination',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'medicine-box',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'health/medicine',
            title: 'Medicines',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'medicine-box',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'health/heat',
            title: 'Heat',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'medicine-box',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'health/weight-measurements',
            title: 'Weight Measurements',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'medicine-box',
            submenu: [],
            disabled: false,
            url: '',
          },
        ],
      },

      {
        path: '',
        title: 'Cattle Identification',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'scan',
        submenu: [],
        disabled: false,
        url: 'https://getlabel.ai/',
      },

      {
        path: '',
        title: 'Cattle Trading',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'rise',
        disabled: false,
        url: '',
        submenu: [
          {
            path: '',
            title: 'Buy',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'shopping',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Sell',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'send',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Transfer',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'partition',
            submenu: [],
            disabled: true,
            url: '',
          },
        ],
      },
    ],
    module: 2,
  },

  {
    path: '',
    title: 'Farm Management',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'experiment',
    submenu: [
      {
        path: '/settings/farms',
        title: 'Farm Registration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'team',
        submenu: [],
        disabled: false,
        url: '',
      },
      {
        path: 'owner-registration',
        title: 'Owner Registration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-add',
        submenu: [],
        disabled: false,
        url: '',
      },
      {
        path: 'herd',
        title: 'Herd Overview',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'field-time',
        submenu: [],
        disabled: true,
        url: '',
      },
      {
        path: '',
        title: 'Feed Management',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'schedule',
        submenu: [
          {
            path: 'feed',
            title: 'Feed Stock',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'database',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            // feed/ration
            path: '',
            title: 'Ration',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
        ],
        disabled: false,
        url: '',
      },
      {
        path: 'milk-yield',
        title: 'Milk Yeild',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'experiment',
        submenu: [],
        disabled: false,
        url: '',
      },
      {
        path: '',
        title: 'BioGas Management',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'reconciliation',
        submenu: [],
        disabled: true,
        url: '',
      },
      {
        path: '',
        title: 'Farm Operations',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'subnode',
        submenu: [
          {
            path: 'administation',
            title: 'ToDo List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'ordered-list',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'administation/income-expense',
            title: 'Income/Expenses',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'line-chart',
            submenu: [],
            disabled: false,
            url: '',
          },
        ],
        disabled: false,
        url: '',
      },
      {
        path: '',
        title: 'Resource Management',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'team',
        submenu: [],
        disabled: true,
        url: '',
      },
      {
        path: 'report-configuration',
        title: 'Report Configuration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'file-text',
        submenu: [],
        disabled: false,
        url: '',
      },
    ],
    disabled: false,
    url: '',
    module: 3,
  },

  {
    path: '',
    title: 'Devices Management',
    icon: 'sliders',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    submenu: [
      {
        path: '',
        title: 'Cow Devices',
        icon: 'file-text',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        submenu: [
          {
            path: 'activity-monitor',
            title: 'Collar Devices',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'box-plot',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: 'geo-location-devices',
            title: 'Geo Location Devices',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'box-plot',
            submenu: [],
            disabled: false,
            url: '',
          },
          {
            path: '',
            title: 'Ear Tags',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'monitor',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Rumen Bolus',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'medicine-box',
            submenu: [],
            disabled: true,
            url: '',
          },
        ],
        disabled: false,
        url: '',
      },

      {
        path: '',
        title: 'Milking Machines',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'filter',
        submenu: [],
        disabled: true,
        url: '',
      },
      {
        path: '',
        title: 'Mastitis Detectors',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'funnel-plot',
        submenu: [],
        disabled: true,
        url: '',
      },
      {
        path: '',
        title: 'Farm Devices',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'table',
        submenu: [
          {
            path: '',
            title: 'Temperature',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'experiment',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Humidity',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'menu',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Other sensors',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'one-to-one',
            submenu: [],
            disabled: true,
            url: '',
          },
        ],
        disabled: false,
        url: '',
      },
      {
        path: '',
        title: '3rd Party Devices',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'team',
        submenu: [],
        disabled: true,
        url: '',
      },
    ],
    disabled: false,
    url: '',
    module: 4,
  },

  {
    path: 'reports',
    title: 'Reports',
    icon: 'file-text',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    submenu: [
      {
        path: 'reports',
        title: 'Reports',
        icon: 'play-square',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        submenu: [],
        disabled: false,
        url: '',
      },
    ],
    disabled: false,
    url: '',
    module: 5,
  },

  {
    path: 'track-n-trace',
    title: 'Track & Trace',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'scan',
    submenu: [],
    disabled: false,
    url: '',
    module: 1,
  },
  {
    path: '',
    title: 'E-Commerce',
    icon: 'chrome',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    submenu: [],
    disabled: true,
    url: '',
    module: 6,
  },
  {
    path: '',
    title: 'Services',
    icon: 'file-text',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    submenu: [
      {
        path: 'services/veterinary',
        title: 'Veterinary Doctor',
        icon: 'medicine-box',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        submenu: [],
        disabled: false,
        url: '',
      },
      {
        path: 'services/pharma',
        title: 'Pharma',
        icon: 'disconnect',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        submenu: [],
        disabled: false,
        url: '',
      },
      {
        path: 'services/transportation',
        title: 'Transportation',
        icon: 'node-index',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        submenu: [],
        disabled: false,
        url: '',
      },
      {
        path: 'services/insurance',
        title: 'Insurance',
        icon: 'security-scan',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        submenu: [],
        disabled: false,
        url: '',
      },
      {
        path: 'services/feed',
        title: 'Feed',
        icon: 'funnel-plot',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        submenu: [],
        disabled: false,
        url: '',
      },
      {
        path: 'services/artificial-insemination',
        title: 'Artificial Insemination',
        icon: 'funnel-plot',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        submenu: [],
        disabled: false,
        url: '',
      },
    ],
    disabled: false,
    url: '',
    module: 7,
  },
  {
    path: 'chat',
    title: 'Cow Gpt',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'message',
    submenu: [],
    disabled: false,
    url: '',
    module: 1,
  },
  {
    path: '',
    title: 'Partner Integration',
    icon: 'snippets',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    submenu: [
      {
        path: '',
        title: 'Partner Onboarding',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'snippets',
        submenu: [
          {
            path: '',
            title: 'Farmers',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Veterniary Doctors',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Insurance companies',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Cattle Traders',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Feed Suppliers',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'Transporters',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: '',
            title: 'State Agencies',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
        ],
        disabled: false,
        url: '',
      },
      {
        path: '',
        title: 'Platform Integration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'snippets',
        disabled: false,
        url: '',
        submenu: [
          {
            path: 'INAPH',
            title: 'INAPH',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
          {
            path: 'e-Gopala',
            title: 'e-Gopala',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'snippets',
            submenu: [],
            disabled: true,
            url: '',
          },
        ],
      },
      {
        path: '',
        title: '3rd Party Integration',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'fork',
        submenu: [],
        disabled: true,
        url: '',
      },
    ],
    disabled: false,
    url: '',
    module: 8,
  },

  {
    path: 'settings',
    title: 'Administration',
    icon: 'setting',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    submenu: [],
    disabled: false,
    url: '',
    module: 9,
  },

  {
    path: 'review-animals',
    title: 'Rating and Certificate',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'safety-certificate',
    submenu: [],
    disabled: false,
    url: '',
    module: 1,
  },

  // {
  //   path: 'settings',
  //   title: 'Administration ',
  //   icon: 'setting',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   submenu: [
  //     {
  //       path: '',
  //       title: 'Data Setup ',
  //       icon: 'user',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       submenu: [
  //         {
  //           path: '',
  //           title: 'Farm',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Groups',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Locations',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Unit of Measure',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Examinations',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Medicines',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Vaccinations',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Semen',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Breeds',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //       ],
  //     },
  //     {
  //       path: '',
  //       title: 'User Management',
  //       icon: 'user',
  //       iconType: 'nzIcon',
  //       iconTheme: 'outline',
  //       submenu: [
  //         {
  //           path: '',
  //           title: 'Add Users',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Delete Users',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //         {
  //           path: '',
  //           title: 'Modify Users',
  //           icon: 'user',
  //           iconType: 'nzIcon',
  //           iconTheme: 'outline',
  //           submenu: [],
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    path: 'users',
    title: 'Admin Users',
    icon: 'user',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    submenu: [],
    disabled: false,
    url: '',
  },

  {
    path: 'youtube-videos',
    title: 'Youtube video tutorials',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'youtube',
    submenu: [],
    disabled: false,
    url: '',
    module: 1,
  },

  // {
  //   path: '/',
  //   title: 'Home',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'dashboard',
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'Livestock',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'snippets',
  //   submenu: [
  //     {
  //       path: 'animal',
  //       title: 'All Animal',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //     {
  //       path: 'animal/newCalf',
  //       title: 'New Calf',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //     {
  //       path: 'animal/newAnimal',
  //       title: 'New Animal (Purchase)',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: 'milk-yield',
  //   title: 'Milk Yield',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'experiment',
  //   submenu: [
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Health Management',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   icon: 'medicine-box',
  //   submenu: [
  //     {
  //       path: 'health',
  //       title: 'Examination',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //     {
  //       path: 'health/medicine',
  //       title: 'Medicine',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //     {
  //       path: 'health/vaccine',
  //       title: 'Vaccine',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Administration',
  //   icon: 'user',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   submenu: [
  //     {
  //       path: 'administation',
  //       title: 'TODO List',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //     {
  //       path: 'administation/income-expense',
  //       title: 'Income/Expenses',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '/apps/mail',
  //   title: 'Herd Overview',
  //   icon: 'folder-view',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'Feed Management',
  //   icon: 'form',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   submenu: [
  //     {
  //       path: 'feed',
  //       title: 'Feed Stock',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },
  //     {
  //       path: 'feed/ration',
  //       title: 'Ration',
  //       icon: '',
  //       iconType: '',
  //       iconTheme: '',
  //       submenu: [],
  //     },

  //   ],
  // },
  // {
  //   path: 'reports',
  //   title: 'Reports',
  //   icon: 'snippets',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   submenu: [],
  // },
  // {
  //   path: 'activity-monitor',
  //   title: 'Activity Monitor',
  //   icon: 'snippets',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   submenu: [],
  // },
  // {
  //   path: 'users',
  //   title: 'User',
  //   icon: 'user',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   submenu: [],
  // },

  // {
  //   path: 'settings',
  //   title: 'Settings  ',
  //   icon: 'setting',
  //   iconType: 'nzIcon',
  //   iconTheme: 'outline',
  //   submenu: [],
  // },
];
