import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { DialogServices } from '../../common-dialogue/dialogs.service';
import { FileUploadPopupComponent } from './File-Upload-popup/file-upload-popup.component';

@Component({
  selector: 'app-file-upload',
  template: `<button *ngIf="!files" nz-button nzType="primary" [nzSize]="size" (click)="openPopup(files)">
    <span nz-icon nzType="paper-clip"></span>
    Upload
  </button>
  <button *ngIf="files" nz-button nzType="primary" [nzSize]="size" (click)="openPopup(files)">
    <span nz-icon nzType="paper-clip"></span>
    Upload ({{files.length}})
  </button>
  `
})


export class FileUploadComponent {
  size: NzButtonSize = 'large';
  @Input() files: any[];
  @Input() entity_type: any;

  @Output() fileIds = new EventEmitter<any>();


  constructor(
    private dialogServices: DialogServices,
  ) {

  }
  openPopup(files: any) {
    this.dialogServices
      .createComponentModal(
        'Files',
        FileUploadPopupComponent,
        { files: files, entity_type: this.entity_type },
      )
      .afterClose.asObservable()
      .subscribe((q) => {
        if (q.files) {
          this.files = q.files;
        }
        this.fileIds.emit(q.id);

      });
  }


}
