import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  of,
  Subscription,
  Subject,
  EMPTY,
} from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { CommonAPIService } from '../shared/services/common-api.service';
import { MessageService } from '../shared/services/message.service';
import { ApiEndpoints } from '../shared/constants/constants';
import { TokenStorageService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private unsubscribe: Subscription[] = [];
  isAuthenticated: boolean;
  currentUserSubject: BehaviorSubject<any>;
  loggedInUserData: any;
  modulesName = [];
  moduleId = [];
  volume_unit: any;
  weight: any;
  currency: any;

  constructor(
    private commonApiService: CommonAPIService,
    private router: Router,
    public messageService: MessageService,
    private tokenService: TokenStorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(undefined);
  }

  NotifyUserUpdated(details) {
    this.currentUserSubject.next(details);
  }
  CurrnetLoggedInUserSubscribe(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  Login(params: any) {
    return new Promise((resolve, reject) => {
      this.commonApiService
        .getPromiseResponse(ApiEndpoints.LoginUser, 'post', params)
        .then((res) => {
          if (res.status === HttpStatusCode.Ok) {
            if (res['message'] === 'User not Verified') {
              this.messageService.showError('User not Verified');
            } else {
              this.messageService.showSuccess('Login Successfully');
              this.tokenService.saveRefreshToken(res.data.refresh_token);
              this.tokenService.saveToken(res.data.access_token);
              this.tokenService.saveUser(JSON.stringify(res.data));
              this.isAuthenticated = true;
              this.NotifyUserUpdated(res.data);
            }
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  Logout() {
    this.tokenService.signOut();
    this.isAuthenticated = false;
    this.currentUserSubject.next(null);
    this.router.navigate(['/auth']).then();
  }

  RefreshToken() {
    let refresh_token = this.tokenService.getRefreshToken();
    let data = {
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
    };

    return this.commonApiService
      .getObservableResponse(ApiEndpoints.LoginUser, 'post', data)
      .pipe(
        map((res: any) => {
          if (res.status === HttpStatusCode.Ok) {
            this.tokenService.saveRefreshToken(res.data.refresh_token);
            this.tokenService.saveToken(res.data.access_token);
            this.tokenService.saveUser(JSON.stringify(res.data));
            this.NotifyUserUpdated(res.data);
            this.isAuthenticated = true;
            return res;
          }
        })
      );
  }

  GetUserDetails() {
    const auth = this.tokenService.getToken();
    if (!auth) {
      // this.Logout();
      return of(undefined);
    }
    return this.commonApiService
      .getObservableResponse(ApiEndpoints.GetLoggedInUserProfile)
      .pipe(
        map((res) => {
          var t: UserModel = new UserModel();
          if (res.data) {
            var tempPermissionModuleArray = res.data.modules;
            this.isAuthenticated = true;
            this.loggedInUserData = res.data;
            this.currency = res.data.currency;
            this.volume_unit = res.data.volume_unit;
            this.weight = res.data.weight;
            tempPermissionModuleArray?.forEach(element => {
              if (res.data.modules) {
                this.modulesName.push(element.module_Name);
                this.moduleId.push(element.module_id);
              }
            });
            this.NotifyUserUpdated(res.data);
            t = res.data;
          } else {
            this.Logout();
          }
          return t;
        })
      );
  }
  emailVerifyAfterSignUp(params: any) {
    return new Promise((resolve, reject) => {
      this.commonApiService
        .getPromiseResponse(ApiEndpoints.EmailVeriftAfterSignUp, 'post', params)
        .then((res) => {
          if (res.status === HttpStatusCode.Ok) {
            this.messageService.showSuccess(
              'Email Verify link send your registerd email id'
            );

            this.tokenService.saveRefreshToken(res.data.refresh_token);
            this.tokenService.saveToken(res.data.access_token);
            this.tokenService.saveUser(JSON.stringify(res.data));

            this.isAuthenticated = true;
            this.NotifyUserUpdated(res.data);
          }

          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  ngOnDestroy() {
    // this.unsubscribe?.forEach((sb) => sb.unsubscribe());
  }
}

export class UserModel {
  id: number;
  // personal information
  firstname: string;
  lastname: string;
  email: string;
}
