import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CommonApiService } from './common-api-wrapper.service';
import { MessageService } from './message.service';

export type APIResponse = {
  code: number;
  data: any;
  message: string;
  status: HttpStatusCode;
};

@Injectable({
  providedIn: 'root',
})
export class CommonAPIService {
  constructor(
    private commonApiService: CommonApiService,
    private messageService: MessageService
  ) {}

  getPromiseResponse(
    apiEndPoint: any,
    methodType: 'get' | 'post' | 'put' | 'patch' | 'delete' = 'get',
    params?: any,
    Argumentlist?: string[],
    showLoading: boolean = true
  ): Promise<APIResponse> {
    if (apiEndPoint) {
      if (Argumentlist) {
        Argumentlist.forEach(function (value, i) {
          apiEndPoint = apiEndPoint.replace('{' + i + '}', arguments[0]);
        });
      }
      return new Promise((resolve, reject) => {
        this.commonApiService
          .getPromiseResponse({
            apiName: apiEndPoint,
            methodType: methodType,
            parameterObject: params,
            showLoading: showLoading,
          })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            if (error.message) {
              this.messageService.showError(error.message);
            }
            reject(error);
          });
      });
    }
  }

  getObservableResponse(
    apiEndPoint: any,
    methodType: 'get' | 'post' | 'put' | 'patch' | 'delete' = 'get',
    params?: any,
    Argumentlist?: string[],
    showLoading: boolean = true
  ): Observable<APIResponse> {
    if (apiEndPoint) {
      if (Argumentlist) {
        Argumentlist.forEach(function (value, i) {
          apiEndPoint = apiEndPoint.replace('{' + i + '}', arguments[0]);
        });
      }
      return this.commonApiService
        .getObservableResponse({
          apiName: apiEndPoint,
          methodType: methodType,
          parameterObject: params,
          showLoading: showLoading,
        })
        .pipe(
          map((res: any) => {
            return res || {};
          })
        );
    }
  }
}
