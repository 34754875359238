<div class="row" *ngIf="formGroup" [formGroup]="formGroup">

    <div class="col-sm-6 col-md-6">
      <div class="form-group">
        <mat-form-field class="example-full-width form-control" appearance="outline" class="border-red">
            <mat-label>Phone Number</mat-label>
            <input formControlName="mobile_no" name="PhoneNumber" matInput placeholder="Phone Number" />
            <mat-error *ngIf="controlHasError(formGroup, 'required', 'mobile_no')">
                This Field is Required.
            </mat-error>
            <mat-error *ngIf="controlHasError(formGroup, 'pattern', 'mobile_no')">
                This Enter 10 Digit.
            </mat-error>
          </mat-form-field>
      </div>
    </div>

    <div class="col-sm-6 col-md-6" *ngIf="isOtpText">
        <div class="form-group">
          <mat-form-field class="example-full-width form-control" appearance="outline" class="border-red">
              <mat-label>OTP</mat-label>
              <input formControlName="auth_code" name="OTP" matInput placeholder="OTP" />
              <mat-error *ngIf="controlHasError(formGroup, 'required', 'auth_code')">
                  This Field is Required.
              </mat-error>
            </mat-form-field>
        </div>
      </div>
  </div>

  <div *nzModalFooter>

    <button mat-raised-button class="mr-2" (click)="destroyModal()">
      Cancel
    </button>
    <button *ngIf="!isOtpText" mat-raised-button color="primary" (click)="sendOtp()">
      <i class="fa-solid fa-send"></i> Send OTP
    </button>

    <button *ngIf="isOtpText" mat-raised-button color="primary" (click)="save()">
        <i class="fa-solid fa-save"></i> Submit
      </button>
  </div>