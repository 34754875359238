import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiEndpoints } from 'src/app/shared/constants/constants';
import { CommonAPIService } from 'src/app/shared/services/common-api.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-file-upload-popup',
  templateUrl: './file-upload-popup.component.html',
})


export class FileUploadPopupComponent implements OnInit {
  description: any = '';
  data: any;
  apiUrl = environment.s3BucketUrl;
  displayedColumns: string[] = [
    'sr',
    'file_name',
    'file_description',
    'Actions',
  ];

  dataSource: any[];
  fileList: NzUploadFile[] = [];
  setFile: any[];
  fileDetails:any= {'id':'','files':[]};
  constructor(
    private modal: NzModalRef,
    private msg: NzMessageService,
    private commonAPIService: CommonAPIService,
    private _ref: ChangeDetectorRef

  ) {
  }
  ngOnInit(): void {
    this.dataSource = [...this.data.files];
  }

  destroyModal(): void {
    let fileId = '';
    this.data.files.forEach(element => {
      fileId += element.file_id + ","
    });
    fileId = fileId.substring(0, fileId.length - 1);
    this.modal.close(fileId);
  }

  openFile(file_url) {
    window.open(this.apiUrl + file_url, "_blank")
  }

  handleChange({ file, fileList }: NzUploadChangeParam): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log(file, fileList);
    }
    if (status === 'done') {
      this.msg.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  handleUpload = () => {
    const formData = new FormData();
    formData.append('entity_type', this.data.entity_type)
    formData.append('description', this.description)

    this.fileList.forEach((file: any) => {
      formData.append('', file);
    });

    this.commonAPIService.getPromiseResponse(ApiEndpoints.file.saveFiles, "post", formData, [], true).then(q => {
      let dataSource = [...this.dataSource]

      q.data.forEach(element => {
        dataSource.push(element);
      });
      this.dataSource = [...dataSource]
      this.fileList = [];
      this.description = "";
      this._ref.detectChanges();
    })
  }

  deleteFile(file_id) {
    let dataSource = [...this.dataSource]

    dataSource.splice(this.dataSource.findIndex(function (i) {
      return i.file_id == file_id;
    }), 1);
    this.dataSource = [...dataSource]
  }


  save() {
    let fileId = '';
    this.dataSource.forEach(element => {
      fileId += element.file_id + ","
    });
    fileId = fileId.substring(0, fileId.length - 1);

   this.fileDetails.id = fileId;
   this.fileDetails.files = this.dataSource

    this.modal.close(this.fileDetails);
  }
}
