import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';

import {
  registerLocaleData,
  PathLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import en from '@angular/common/locales/en';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';

import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ChangePasswordComponent } from './pages/profile/change-password/change-password.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NgSelectOption, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpRequestInterceptor } from './shared/interceptor/auth-http-request.interceptor';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { MatButtonModule } from '@angular/material/button';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { DialogServices } from './shared/common-dialogue/dialogs.service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { MatNativeDateModule } from '@angular/material/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { AuthService } from './authentication/auth.service';
import { TokenStorageService } from './authentication/token.service';
import { ConfirmationDialogComponent } from './shared/common-dialogue/ConfirmationDialog';
import { NotPermissionComponent } from './not-permission/not-permission.component';
import { GoogleChartsModule } from 'angular-google-charts';

import { MarkdownModule } from 'ngx-markdown';


registerLocaleData(en);

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.GetUserDetails().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    EmailVerifyComponent,
    ConfirmationDialogComponent,
    NotPermissionComponent,
  ],
  imports: [
    OverlayModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
    NzAlertModule,
    NzTabsModule,
    NzCardModule,
    NzAvatarModule,
    NzUploadModule,
    NzButtonModule,
    NzGridModule,
    NzInputModule,
    NzDatePickerModule,
    NzSelectModule,
    NzFormModule,
    ReactiveFormsModule,
    NzSpinModule,
    MatButtonModule,
    NzModalModule,
    MatNativeDateModule,
    SharedModule,
    MarkdownModule.forRoot(),
    GoogleChartsModule.forRoot({
      mapsApiKey: 'AIzaSyCLnLzpNTxYeQECTcY40mx0HLsuhwgaoCM',
    }),
  ],
  providers: [
    DialogServices,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpRequestInterceptor,
      multi: true,
    },
    AuthService,
    ThemeConstantService,
    NzNotificationService,
    HttpClientModule,
    TokenStorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
