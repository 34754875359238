import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MessageService } from '../services/message.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../authentication/auth.service';
import { TokenStorageService } from '../../authentication/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private router: Router,
    private httpClient: HttpClient,
    private authService: AuthService,
    private tokenServie: TokenStorageService
  ) {}
  private isRefreshing = false;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenServie.getToken();
    const req = request.clone({
      setHeaders: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: 'Bearer ' + token,
      },
    });
    if (!token) {
      // this.authService.Logout();
    }

    return next.handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          if (req.url.includes('login')) {
            this.authService.Logout();
          } else {
            return this.handle401Error(req, next);
          }
        }

        return throwError(err);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      const token = this.tokenServie.getToken();

      if (token) {
        return this.authService.RefreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            return next.handle(
              this.addTokenHeader(request, token.data.access_token)
            );
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authService.Logout();
            return throwError(err);
          })
        );
      } else {
        this.authService.Logout();
        return next.handle(request);
      }
    }
  }
}
