import { AuthService } from './../../../authentication/auth.service';
import { Component, OnInit } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./sidenav.component.css'],
})
export class SideNavComponent implements OnInit {
  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  newRoutes = [];
  constructor(
    private themeService: ThemeConstantService,
    public authServices: AuthService
  ) {}

  ngOnInit(): void {
    let arr = [];
    for (const iterator of ROUTES) {
      var index = this.authServices.moduleId.indexOf(iterator.module);

      // TODO: REMOVE TRUE TO AUTH MODULE BASE WORKING FINE
      if (index !== -1 || true) {
        arr.push(iterator);
      }
    }

    this.menuItems = arr.filter((menuItem) => menuItem);
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
  }

  closeMobileMenu(): void {
    if (window.innerWidth < 992) {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }
  goToUrl(url): void {
    window.open(url, '_blank');
  }
}
// export class newArray {
//   disabled: boolean;
//   icon: String;
//   iconTheme: String;
//   iconType: String;
//   path: String;
//   submenu: [];
//   title: String;
//   url: String;
// }
