import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

@Component({
  template: '',
})
export class BaseClass {
  maxDate = new Date();
  today = new Date();
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  };
  //pagination helper
  pageSizes = [10, 20, 30];
  params: any = {
    Page: 1,
    PageSize: 10,
    SortColumn: '',
    SortOrder: '',
    Filters: '',
    SearchText: '',
  };
  totalResults = 0;

  dataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  // helpers for View
  isControlValid(formgroup: FormGroup, controlName: string): boolean {
    const control = formgroup.controls[controlName];
    return control.valid && (control.dirty || control.touched);
  }

  isControlInvalid(formgroup: FormGroup, controlName: string): boolean {
    const control = formgroup.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }

  controlHasError(formgroup: FormGroup, validation, controlName): boolean {
    const control = formgroup.controls[controlName];
    return control.hasError(validation) && (control.dirty || control.touched);
  }

  isControlTouched(formgroup: FormGroup, controlName): boolean {
    const control = formgroup.controls[controlName];
    return control.dirty || control.touched;
  }
}
