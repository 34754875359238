<div class="header scroll">
  <div class="logo logo-dark">
    <a href="">
      <img src="assets/images/logo/cowLogo.png" style="margin-top: 19px; width: 208px" alt="Logo" />
      <img class="logo-fold" src="assets/images/logo/fevicon.png" style="margin-top: 5px; width: 70px" alt="Logo" />
    </a>
  </div>
  <div class="logo logo-white">
    <a>
      <img src="assets/images/logo/logo.jpeg" alt="Logo" />
      <img class="logo-fold" src="assets/images/logo/logo.jpeg" alt="Logo" />
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i nz-icon [nzType]="isFolded ? 'menu-unfold' : 'menu-fold'" theme="outline"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i nz-icon [nzType]="isExpand ? 'menu-fold' : 'menu-unfold'" theme="outline"></i>
        </a>
      </li>
      <li *ngIf="farmData">
        <mat-form-field appearance="outline" style="
            padding: 0 0.75em 0 0.75em !important;
            margin-top: 1.25em !important;
            position: relative !important;
            width: 64% !important;
          ">
          <mat-label>Current Farm</mat-label>
          <mat-select placeholder="Favorite food" [(ngModel)]="selFarm" (ngModelChange)="onSelected()">
            <mat-option *ngFor="let item of farmData" [value]="item.key">
              {{ item.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
    </ul>
    <ul class="nav-right">
      <div class="row">
        <div id="google_translate_element"
          class="g-translate position-static ms-3 d-flex justify-content-center align-items-center">
          <!-- <i class="fa-solid fa-language"></i> -->
          <!-- <i class="fas fa-globe"></i> -->
          <i nz-tooltip="Reset Default English Language" class="fa-solid fa-arrows-rotate mr-2 " (click)="resetLan()"></i>
        </div>
      </div>

      <!-- EE, d MM, y -->
      <b class="mr-2 dateTime">{{ date + " &nbsp; &nbsp;" + time }}</b>

      <a class="mr-2">
        <i nz-icon nzType="search" class="font-size-20" theme="outline"></i>
      </a>

      <!-- <mat-form-field appearance="outline" style="
      padding: 0 0.75em 0 0.75em !important;
      margin-top: 1.25em !important;

    ">
        <mat-label>Change Farm</mat-label>
        <mat-select>
          <mat-option value="en"> English</mat-option>
          <mat-option value="hi">Hindi</mat-option>
          <mat-option value="gu">Gujarati</mat-option>
          <mat-option value="ma">Marathi</mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <button nz-button nz-dropdown [nzDropdownMenu]="language">English</button>

      <nz-dropdown-menu #language="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item>English</li>
          <li nz-menu-item>Hindi</li>
          <li nz-menu-item>Gujarati</li>
          <li nz-menu-item>Marathi</li>
        </ul>
      </nz-dropdown-menu> -->




      <nz-spin [nzSpinning]="loading" nzTip="Loading Detail..." [nzSize]="'large'">
        <li>
          <span class="p-h-10 pointer" nz-dropdown [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
            <nz-avatar nz-tooltip="Support" nzTooltipColor="green" nzTooltipPlacement="bottomLeft" class="img"
              style="background-color: green" nzIcon="customer-service"></nz-avatar>
          </span>
        </li>
      </nz-spin>
      <nz-spin [nzSpinning]="loading" nzTip="Loading Detail..." [nzSize]="'large'">
        <li>
          <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
            [nzPlacement]="'bottomRight'">
            <nz-avatar [nzIcon]="'user'" class="img" nzSrc="assets/images/avatars/pro.png"></nz-avatar>
          </span>
          <nz-dropdown-menu #profile="nzDropdownMenu">
            <ul nz-menu class="p-b-15 p-t-20">
              <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                <div class="d-flex m-r-50">
                  <nz-avatar nzSize="large" class="img" nzSrc="assets/images/avatars/pro.png"></nz-avatar>
                  <div class="m-l-10" *ngIf="data">
                    <p class="m-b-0 text-dark font-weight-semibold">
                      {{ data.first_name + " " + data.last_name }}
                    </p>
                    <p class="m-b-0 opacity-07">{{ data.email_address }}</p>
                  </div>
                </div>
              </li>
              <li nz-menu-item routerLink="adminDetail">
                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                    <span class="m-l-10">View Profile</span>
                  </div>
                </a>
              </li>

              <li nz-menu-item (click)="openDilog()">
                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="mobile" theme="outline"></i>
                    <span class="m-l-10">Update Phone Number</span>
                  </div>
                </a>
              </li>

              <li nz-menu-item routerLink="changePassword">
                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                    <span class="m-l-10">Change Password</span>
                  </div>
                </a>
              </li>
              <li nz-menu-item>
                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="credit-card" theme="outline"></i>
                    <span class="m-l-10">Billing</span>
                  </div>
                </a>
              </li>

              <!-- <li nz-menu-item routerLink="settings">
                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="setting" nzTheme="outline"></i>
                    <span class="m-l-10">Settings</span>
                  </div>
                </a>
              </li> -->

              <li nz-menu-item (click)="logout()">
                <a class="p-v-5 d-flex align-items-center justify-content-between text-decoration-none">
                  <div>
                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                    <span class="m-l-10">Logout</span>
                  </div>
                  <!--                  <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>-->
                </a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </li>
      </nz-spin>
    </ul>
  </div>
</div>
