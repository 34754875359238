import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  toggleMenu = new BehaviorSubject(true);

  constructor(private notification: NzNotificationService) {}

  showSuccess(message: string, type = 'success'): void {
    this.notification.create(type, 'Success', message, {
      nzPauseOnHover: false,
      nzDuration: 1000,
    });
  }

  showError(message: string, type: string = 'error'): void {
    this.notification.create(type, 'Error', message, {
      nzPauseOnHover: false,
      nzDuration: 1000,
    });
  }
}
