import { NotPermissionComponent } from './not-permission/not-permission.component';
import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
  PreloadAllModules,
  ExtraOptions,
} from '@angular/router';

import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';

import { AuthGuard } from './authentication/auth.guard';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { PrivacyPolicy } from './privacy-policy/privacy-policy.component';
import { TermsAndConditions } from './terms-and-conditions/terms-and-conditions.component';

const appRoutes: Routes = [
  {
    path: 'auth',
     canActivate: [AuthGuard],
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicy,
    data: {
      title: 'Privacy Policy',
    },
  },
  {
    path: 'termsofuse',
    component: TermsAndConditions,
    data: {
      title: 'Term of Use',
    },
  },
  {
    path: 'emailverify',
    component: EmailVerifyComponent,
    data: {
      title: '',
    },
  },
  {
    path: 'notpermission',
    component: NotPermissionComponent,
    data: {
      title: '',
    },
  },
  {
    path: '',
     canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy:'always'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
