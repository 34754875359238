import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../shared/services/message.service';
import { Router } from '@angular/router';
import { CommonAPIService } from 'src/app/shared/services/common-api.service';
import { ApiEndpoints } from 'src/app/shared/constants/constants';
import { AuthService } from '../authentication/auth.service';

@Component({
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css'],
})
export class TermsAndConditions implements OnInit {
  
  constructor(
   
  ) { }

  ngOnInit(): void {
   
  }

 
}
