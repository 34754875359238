<div class="row">
  <div class="col-12">
    <nz-upload
      nzType="drag"
      [nzMultiple]="true"
      [nzBeforeUpload]="beforeUpload"
      [(nzFileList)]="fileList"
      (nzChange)="handleChange($event)"
    >
      <p class="ant-upload-drag-icon">
        <span nz-icon nzType="inbox"></span>
      </p>
      <p class="ant-upload-text">Click or drag file to this area to upload</p>
      <p class="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading
        company data or other band files
      </p>
    </nz-upload>
  </div>

  <textarea
    nz-input
    [(ngModel)]="description"
    style="margin: 20px 9px; width: 100%"
    [nzAutosize]="{ minRows: 2, maxRows: 6 }"
    placeholder="Enter Description Here"
    rows="2"
  ></textarea>
  <div
    style="
      text-align: end;
      width: 100%;
      padding-bottom: 20px;
      margin-right: 9px;
    "
  >
    <button
      mat-raised-button
      color="primary"
      (click)="handleUpload()"
      [disabled]="fileList.length === 0"
    >
      Upload
    </button>
  </div>

  <div class="col-12">
    <nz-card *ngIf="data.files.length > 0 || this.dataSource.length > 0" nzTitle="Uploaded Files">
      <div class="responsive_table text-center">
        <mat-table matSort [dataSource]="dataSource">
          <ng-container matColumnDef="sr">
            <mat-header-cell *matHeaderCellDef mat-sort-header disabled
              >No.</mat-header-cell
            >
            <mat-cell *matCellDef="let element; let i = index">
              {{ this.paginator?.pageIndex * this.paginator?.pageSize + i + 1 }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="file_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >File Name</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              {{ element.file_name }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="file_description">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >File Description</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              {{ element.file_description }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Actions">
            <mat-header-cell style="flex: 0.4" *matHeaderCellDef
              >Actions</mat-header-cell
            >
            <mat-cell style="flex: 0.4" *matCellDef="let element">
              <button
                (click)="openFile(element.file_url)"
                mat-mini-fab
                style="margin-right: 8px"
                color="primary"
              >
                <i class="fas fa-download"></i>
              </button>

              <button
                mat-mini-fab
                color="warn"
                (click)="deleteFile(element.file_id)"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </nz-card>
  </div>

  <div style="margin: 0px 9px;" *nzModalFooter>
    <button mat-raised-button class="mr-2" (click)="destroyModal()">
      Cancel
    </button>
    <button mat-raised-button color="primary" (click)="save()">
      <i class="fa-solid fa-save"></i> Submit
    </button>
  </div>
</div>
