const dynamicScripts = [
  'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
];

export class StaticPageJSHelper {
  public static loadScript() {
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
}
