import { SystemModule } from './../shared/constants/constants';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { MessageService } from '../shared/services/message.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private defaultRouteUnAuthenticated: string = '/auth';
  private defaultRouteAuthenticated: string = '/dashboard';
  private defaultRoutePermissionDenied: string = '/access-denied';
  private notpermission: string = '/notpermission';

  constructor(private route: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isAuthenticated) {
      if (state.url.includes('auth')) {
        return true;
      }
      this.route.navigateByUrl(this.defaultRouteUnAuthenticated);
      return false;
    } else {
      //TODO: you just need to uncommet to start working the module based auth
      // if (this.authService.isAuthenticated) {
      //   if (route.data) {
      //     var index = this.authService.moduleId.indexOf(route.data.module);
      //     if (route.data.module && index !== -1) {
      //       return true;
      //     }
      //     if (route.data.module && index === -1) {
      //       this.route.navigateByUrl(this.notpermission);
      //       return true;
      //     }
      //   } else {
      //     this.route.navigateByUrl(this.defaultRouteAuthenticated);
      //   }
      // }
      if (state.url.includes('auth')) {
        this.route.navigateByUrl(this.defaultRouteAuthenticated);
      }
      return true;
    }
  }
}
