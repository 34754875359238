import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonAPIService } from '../shared/services/common-api.service';
import { MessageService } from '../shared/services/message.service';
import { ApiEndpoints } from '../shared/constants/constants';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.css'],
})
export class EmailVerifyComponent implements OnInit {
  verify: boolean;
  approved: boolean;
  reject: boolean;
  constructor(
    private router: Router,
    public commonService: MessageService,
    private commonAPIService: CommonAPIService
  ) {}

  ngOnInit(): void {
    this.verify = true;
    this.approved = false;
    this.reject = false;
    const string = this.router.url;
    let tokenString = string.split('?token=');
    if (tokenString[1]) {
      this.commonAPIService
        .getPromiseResponse(ApiEndpoints.EmailVerify, 'post', {
          email_validation_token: tokenString[1],
        })
        .then((res) => {
          if (res.data.is_verified === true) {
            this.verify = false;
            this.approved = true;
            this.reject = false;
          } else {
            this.verify = false;
            this.approved = false;
            this.reject = true;
          }
        });
    }
  }
}
