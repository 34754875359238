import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  template: `
    <div class="col-lg-12 mb-4 text-right">
      <div class="flex-container float-right">
        <button
          type="button"
          mat-raised-button
          class="mr-2"
          (click)="onDismiss()"
        >
          Cancel
        </button>
        <button (click)="onConfirm()" mat-raised-button color="primary">
          <i class="fa-solid fa-save"></i> Submit
        </button>
      </div>
    </div>
  `,
})
export class ConfirmationDialogComponent implements OnInit {
  data: any;
  // 'type',
  constructor(private modal: NzModalRef) {}

  ngOnInit(): void {}
  onDismiss() {
    this.modal.close(false);
  }
  onConfirm() {
    this.modal.close(true);
  }
}
