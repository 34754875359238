<main class="admin-main" [ngStyle]="{ display: verify ? 'block' : 'none' }">
  <div
    class="container-fluid p-h-0 p-v-20 bg h-100"
    style="background-image: url('assets/images/others/login-3.png')"
  >
    <div class="d-flex flex-column justify-content-between">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7 mt-5 col-lg-5 m-h-auto">
            <!-- <nz-card class="m-b-60 shadow-lg"> -->
            <div class="card text-center">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <img
                  draggable="false"
                  src="../../assets/images/logo/logo.jpeg"
                  width="100%"
                  alt=""
                  class="src"
                />
              </div>
              <img
                src="../../assets/images/others/wait.gif"
                width="40%"
                alt=""
                class="src"
              />
              <!-- </nz-card> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<main class="admin-main" [ngStyle]="{ display: approved ? 'block' : 'none' }">
  <div
    class="container-fluid p-h-0 p-v-20 bg h-100"
    style="background-image: url('assets/images/others/login-3.png')"
  >
    <div class="d-flex flex-column justify-content-between">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7 mt-5 col-lg-5 m-h-auto">
            <!-- <nz-card class="m-b-60 shadow-lg"> -->
            <div class="card text-center">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <img
                  draggable="false"
                  src="../../assets/images/others/aproved.gif"
                  width="60%"
                  alt=""
                  class="src"
                />
              </div>
              <h1>Thank you</h1>
              <p>Your email has been verified. your account is now active.</p>
              <p>Please use the link below to login to your account.</p>
              <button mat-raised-button color="primary" routerLink="">
                LOGIN TO YOUR ACCOUNT
              </button>
            </div>
            <!-- </nz-card> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<main class="admin-main" [ngStyle]="{ display: reject ? 'block' : 'none' }">
  <div
    class="container-fluid p-h-0 p-v-20 bg h-100"
    style="background-image: url('assets/images/others/login-3.png')"
  >
    <div class="d-flex flex-column justify-content-between">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7 mt-5 col-lg-5 m-h-auto">
            <!-- <nz-card class="m-b-60 shadow-lg"> -->
            <div class="card text-center">
              <div
                style="
                  border-radius: 200px;
                  height: 200px;
                  width: 200px;
                  background: #f8faf5;
                  margin: 0 auto;
                "
              >
                <img
                  draggable="false"
                  src="../../assets/images/others/reject1.gif"
                  width="60%"
                  alt=""
                  class="src"
                />
              </div>
              <h1>Sorry,</h1>
              <p>
                Your email has been not verified. your account is not active.
              </p>
              <p>Please use the link below to login to your account.</p>
              <button mat-raised-button color="primary" routerLink="">
                LOGIN TO YOUR ACCOUNT
              </button>
            </div>
            <!-- </nz-card> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
