import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})


export class FooterComponent implements OnInit{
    year: any;
    showFooter = true;
    constructor(private router: Router) { this.year = new Date().getFullYear() }

  ngOnInit() {
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     this.showFooter = !this.isSpecificRouter(event.url); // You can customize this condition
    //   }
    // });
  }

    // private isSpecificRouter(url: string): boolean {
    //     return url.startsWith('/chat'); 
    //   }
}



