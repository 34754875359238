import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/authentication/auth.service';
import { BaseClass } from 'src/app/helper/baseclass';
import { ApiEndpoints } from 'src/app/shared/constants/constants';
import { CommonAPIService } from 'src/app/shared/services/common-api.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-change-phone-number',
  templateUrl: './change-phone-number.component.html',
  styleUrls: ['./change-phone-number.component.css']
})
export class ChangePhoneNumberComponent extends BaseClass implements OnInit {
  formGroup: FormGroup;
  isOtpText: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public messageService: MessageService,
    public commonApiService: CommonAPIService,
    private modal: NzModalRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      mobile_no: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      auth_code: ['', [Validators.required]],
    });
  }

  sendOtp() {
    var reqBody = {
      "mobile_no": this.formGroup.value.mobile_no
    }
    if (this.formGroup.value.mobile_no) {
      this.commonApiService
        .getPromiseResponse(
          ApiEndpoints.login.sendotpChangeNumber,
          'post',
          reqBody,
          [],
          true
        )
        .then((q) => {
          this.isOtpText = true;
          this.messageService.showSuccess('OTP Send successfully');
        });
    }
    else {
      this.isOtpText = false;
      this.formGroup.markAllAsTouched();
    }
  }

  destroyModal(): void {
    this.modal.close(false);
  }

  save() {
    if (this.formGroup.valid) {
      this.commonApiService
        .getPromiseResponse(
          ApiEndpoints.login.changePhoneNumber,
          'post',
          this.formGroup.value,
          [],
          true
        )
        .then((q) => {
          this.messageService.showSuccess(
            'Phone number changed successfully'
          );
          this.modal.close(true);
        });
    }
  }

}
