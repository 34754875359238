/* api code const */

export enum SystemModule {
  dashboard = 1,
  livestock = 2,
  farmManagement = 3,
  deviceManagement = 4,
  reports = 5,
  ECommerce = 6,
  services = 7,
  administration = 8,
}
export enum GrantTypes {
  password,
  refresh_token,
  client_credentials,
  key,
}

export const REPORT_CONFIGURATION_TYPE = [
  {
    key: 160,
    value: 'InActiveDeviceReport',
  },
  {
    key: 161,
    value: 'GeofenceReport',
  },
  { key: 162, value: 'HealthReport' },
];

export const MAINSTATUS = [
  {
    key: 40,
    value: 'Calf',
  },
  {
    key: 41,
    value: 'Heifer ',
  },
  {
    key: 42,
    value: 'Ready',
  },
  {
    key: 45,
    value: 'Milking',
  },
  {
    key: 46,
    value: 'Dryoff',
  },
  {
    key: 159,
    value: 'Animal Dead',
  },
];

export const SUBSTATUSREADY = [
  {
    key: 58,
    value: 'Artificial Insemination',
  },
  {
    key: 59,
    value: 'NaturalMating',
  },
  {
    key: 60,
    value: 'EmbryoTransfer',
  },
];

export const SUBSTATUSPREGNENT = [
  {
    key: 66,
    value: 'Not Pregnant',
  },
  {
    key: 67,
    value: 'Uncertain',
  },
  {
    key: 68,
    value: 'Abortion',
  },
];

export const DEVICESTATUS = [
  {
    key: 1,
    value: 'True',
  },
  {
    key: 2,
    value: 'False',
  },
];

export const ALERTNOTES = [
  {
    key: 1,
    value: 'In',
  },
  {
    key: 2,
    value: 'Out',
  },
  {
    key: 3,
    value: 'Heat',
  },
  {
    key: 4,
    value: 'Health',
  },
];

export const ALERTSTATUS = [
  {
    key: 'All',
    value: 'ffalse',
  },
  {
    key: 'Acknowledged',
    value: 'true',
  },
  {
    key: 'Open',
    value: 'false',
  },
];

export const FarmOperation = [
  {
    key: 1,
    value: true,
  },
  {
    key: 2,
    value: false,
  },
];

export const NEXTSTEPSTATUS = [
  {
    key: 71,
    value: 'No Action',
  },
  {
    key: 72,
    value: 'Perform AI',
  },
];

export enum Status {
  Active = 1,
  Suspend = 2,
  Delete = 3,
  Impersonate = 4,
  Terminate = 5,
  InActive = 6,
  Archive = 33,
  Draft = 70,
  Rejected = 102,
  Fulfilled = 103,
  Retruned = 82,
  Disposed = 83,
  Published = 143,
}

export enum Gender {
  Male = 1,
  Female = 2,
  Other = 3,
}

export enum Constant {
  Status = 1,
  WeightUnit = 2,
  VolumeUnit = 3,
  Currency = 4,
  SystemParametersType = 5,
  MedialTerms = 6,
  IllnessType = 7,
  TreatmentType = 8,
  TestType = 9,
  OtherTermType = 10,
  SemenStatus = 11,
  FarmAnimalStatus = 12,
  Gender = 13,
  CalvingType = 14,
  Occurrence = 15,
  Color = 16,
  FeedType = 17,
  FeedInventory = 18,
  PregnancyType = 19,
  InseminationDetectionType = 20,
  InseminationMethod = 21,
  InseminationTypeService = 22,
  HeatNextStep = 23,
  BusinessCategory = 26,
  SpeciesType = 27,
  DischargeType = 28,
  NextStep = 29,
  SocialStatus = 30,
  LandHolding = 31,
  VaccinationFor = 32,
  VaccinationType = 33,
  ALERTNOTES,
}

export enum FarmOperationType {
  ThingsBoardConFiguration = 143,
  DeviceDataConfiguration = 144,
  GraphConfiguration = 145,
  GeoFenceConfiguration = 148,
  AnimalMapRecordConfiguration = 154,
}

export enum EntityType {
  FarmRegistration = 1,
  OwnerRegistration = 2,
  Heat = 3,
  MedicalExamination = 4,
  Vaccination = 5,
  Pregnancy = 6,
  MilkYeild = 7,
  Insemination = 8,
  BodyScore = 9,
  Weight = 10,
  Service = 11,
  Medicine = 12,
}

export const BUSINESSCATEGORY = [
  {
    key: 103,
    value: 'Veterinary Doctors & Providers',
  },
  {
    key: 104,
    value: 'Cattle Pharma Providers',
  },
  {
    key: 105,
    value: 'Cattle Insurance Providers',
  },
  {
    key: 106,
    value: 'Artificial Insemination Providers',
  },
  {
    key: 107,
    value: 'Cattle Transportation Agencies',
  },
  {
    key: 108,
    value: 'Cattle Feed Providers',
  },
];

export enum MedicalTerms {
  IllnessList = 17,
  TretmentTypeList = 18,
  MedicineList = 19,
  VaccineList = 64,
  Tests = 20,
  OtherTerms = 21,
  Transponder = 22,
}

export enum DropdownValue {
  WeightUnit = '2',
  VolumeUnit = '3',
  Currency = '4',
}

export const TIMESTATUS = [
  {
    key: 1,
    value: '06:00:00',
  },
  {
    key: 2,
    value: '06:15:00',
  },
  {
    key: 3,
    value: '06:30:00',
  },
  {
    key: 4,
    value: '06:45:00',
  },
  {
    key: 5,
    value: '07:00:00',
  },
  {
    key: 6,
    value: '07:15:00',
  },
  {
    key: 7,
    value: '07:30:00',
  },
  {
    key: 8,
    value: '07:45:00',
  },
  {
    key: 9,
    value: '08:00:00',
  },
  {
    key: 10,
    value: '08:15:00',
  },
  {
    key: 11,
    value: '08:30:00',
  },
  {
    key: 12,
    value: '08:45:00',
  },
  {
    key: 13,
    value: '09:00:00',
  },
  {
    key: 14,
    value: '09:15:00',
  },
  {
    key: 15,
    value: '09:30:00',
  },
  {
    key: 16,
    value: '09:45:00',
  },
  {
    key: 17,
    value: '10:00:00',
  },
  {
    key: 18,
    value: '10:15:00',
  },
  {
    key: 19,
    value: '10:30:00',
  },
  {
    key: 20,
    value: '10:45:00',
  },
  {
    key: 21,
    value: '11:00:00',
  },
  {
    key: 22,
    value: '11:15:00',
  },
  {
    key: 23,
    value: '11:30:00',
  },
  {
    key: 24,
    value: '11:45:00',
  },
  {
    key: 25,
    value: '12:00:00',
  },
  {
    key: 26,
    value: '12:15:00',
  },
  {
    key: 27,
    value: '12:30:00',
  },
  {
    key: 28,
    value: '12:45:00',
  },
  {
    key: 29,
    value: '13:00:00',
  },
  {
    key: 30,
    value: '13:15:00',
  },
  {
    key: 31,
    value: '13:30:00',
  },
  {
    key: 32,
    value: '13:45:00',
  },
  {
    key: 33,
    value: '14:00:00',
  },
  {
    key: 34,
    value: '14:15:00',
  },
  {
    key: 35,
    value: '14:30:00',
  },
  {
    key: 36,
    value: '14:45:00',
  },
  {
    key: 37,
    value: '15:00:00',
  },
  {
    key: 38,
    value: '15:15:00',
  },
  {
    key: 39,
    value: '15:30:00',
  },
  {
    key: 40,
    value: '15:45:00',
  },
  {
    key: 41,
    value: '16:00:00',
  },
  {
    key: 42,
    value: '16:15:00',
  },
  {
    key: 43,
    value: '16:30:00',
  },
  {
    key: 44,
    value: '16:45:00',
  },
  {
    key: 45,
    value: '17:00:00',
  },
  {
    key: 46,
    value: '17:15:00',
  },
  {
    key: 47,
    value: '17:30:00',
  },
  {
    key: 48,
    value: '17:45:00',
  },
  {
    key: 49,
    value: '18:00:00',
  },
  {
    key: 50,
    value: '18:15:00',
  },
  {
    key: 51,
    value: '18:30:00',
  },
  {
    key: 52,
    value: '18:45:00',
  },
  {
    key: 53,
    value: '19:00:00',
  },
  {
    key: 54,
    value: '19:15:00',
  },
  {
    key: 55,
    value: '19:30:00',
  },
  {
    key: 56,
    value: '19:45:00',
  },
  {
    key: 57,
    value: '20:00:00',
  },
  {
    key: 58,
    value: '20:15:00',
  },
  {
    key: 59,
    value: '20:30:00',
  },
  {
    key: 60,
    value: '20:45:00',
  },
  {
    key: 61,
    value: '21:00:00',
  },
  {
    key: 62,
    value: '21:15:00',
  },
  {
    key: 63,
    value: '21:30:00',
  },
  {
    key: 64,
    value: '21:45:00',
  },
  {
    key: 65,
    value: '22:00:00',
  },
  {
    key: 66,
    value: '22:15:00',
  },
  {
    key: 67,
    value: '22:30:00',
  },
  {
    key: 68,
    value: '22:45:00',
  },
  {
    key: 69,
    value: '23:00:00',
  },
  {
    key: 70,
    value: '23:15:00',
  },
  {
    key: 71,
    value: '23:30:00',
  },
  {
    key: 72,
    value: '23:45:00',
  },
];

/* static const for validation*/

export const emailValidationRegx =
  '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
export const zipCodeValidationRegx = '^[a-zA-Z0-9- ]*$';
export const postalCodeValidationRegx = '^[a-zA-Z0-9- ]*$';
export const phonenoValidationRegx = '^[0-9]*$';

export const fileValidationRegx = {
  Image: '(.*?).(png|PNG|jpg|JPG|jpeg|JPEG)$',
  PDFDocument: '(.*?).(pdf|PDF)$',
  CSV: '(.*?).(csv|CSV)$',
  PDFImageDocument:
    '(.*?).(png|PNG|jpg|JPG|jpeg|JPEG|pdf|PDF|doc|DOC|docx|DOCX)$',
  Document:
    '(.*?).(png|PNG|jpg|JPG|jpeg|JPEG|xls|XLS|xlsx|XLSX|pdf|PDF|doc|DOC|docx|DOCX)$',
  CSVXLSFile: '(.*?).(xls|XLS|xlsx|XLSX|csv|CSV|txt|TXT)$',
  PPTImagePDFTxtDocument:
    '(.*?).(png|PNG|jpg|JPG|jpeg|JPEG|xls|XLS|xlsx|XLSX|pdf|PDF|doc|DOC|docx|DOCX|ppt|PPT|pptx|PPTX|txt|TXT)$',
  DOCXLSFile: '(.*?).(xls|XLS|xlsx|XLSX|doc|DOC|docx|DOCX|pdf|PDF)$',
};
export const fileValidationMessageRegx = {
  Image: 'Allowed file types: .jpg, .jpeg, .png',
  PDFDocument: 'Allowed file type: .pdf',
  CSV: 'Allowed file type: .csv',
  PDFImageDocument: 'Allowed file types: .png, .jpg, .jpeg, .pdf, .doc, .docx',
  Document:
    ' Allowed file types: .png, .jpg, .jpeg, .xls, .xlsx, .pdf, .doc, .docx',
  CSVXLSFile: 'Allowed file types: .xls, .xlsx, .csv',
  PPTImagePDFTxtDocument:
    'Allowed file types: .png, .jpg, .jpeg, .xls, .xlsx, .pdf, .doc, .docx, .ppt, .pptx, .txt',
  DOCXLSFile: 'Allowed file types: .xls, .xlsx,.doc, .docx, .pdf',
  ValidPDFFile: 'Upload a valid .pdf file',
};
export const ApiEndpoints = {
  // AUTH ENDPOINT
  SignupUser: 'users/register',
  LoginUser: 'login',
  RefreshToken: 'login',
  EmailUniqueCheck: 'users/register/check_unique_email',
  FarmNameUniqueCheck: 'users/check_unique_farm',
  GetFarmsListByEmail: 'users/farms_by_email',
  ForgotPassword: 'users/forgot_password',
  ForgotPasswordConfirmCode: 'users/confirm_code',
  SetPassword: 'users/set_password',
  EmailVerify: 'verify_email',
  EmailVeriftAfterSignUp: 'send_email_verification',
  emailCheckExistingInUser: 'users/check_existing',
  // USER PROFILE ENDPOINT
  GetLoggedInUserProfile: 'users/get_logged_in_user',
  UserChangePassword: 'users/change_password',
  AddFarmUser: 'users/add',
  GetFarmUserList: 'users/list',
  GetFarmUserDetails: 'users/{0}',
  GetFarmShadesList: 'farm_shed/list',
  // change farm id
  changeFarmId: 'users/update_farm',
  profileUpdate: 'users/edit_profile',
  userFarmList: 'users/farm_list',
  farmDelete: 'users/farm/{0}',
  farmEdit: 'users/edit_farm_name',
  profilepictureupdate: 'users/edit_profile_picture',
  animallocaton: 'farm_animal/location',
  animalGeoFence: 'farm_animal/geofense_location',
  getProductTT: 'products/{0}',
  animalLocationMap: 'farm_animal/{0}/geo_location',

  dropdown: {
    moduleList: 'dropdowns/modules_list',
    systemParameterDropdown: 'dropdowns/{0}',
    breedlist: 'dropdowns/breeds',
    Constantlist: 'dropdowns/{0}',
    Gender: 'dropdowns/{0}',
    Status: 'dropdowns/{0}',
    Occurrence: 'dropdowns/{0}',
    Color: 'dropdowns/{0}',
    Calving: 'dropdowns/{0}',
    farm_sheds: 'dropdowns/farm_sheds',
    animals: 'dropdowns/animals',
    examination: 'dropdowns/farm_examination',
    mappedDeviceList: 'dropdowns/mapped-device',

    resultexamination: 'dropdowns/farm_examination/{0}/result',
    medicine: 'dropdowns/medicines',
    vaccine: 'dropdowns/vaccines',

    transponders: 'dropdowns/transponders',
    groups: 'dropdowns/groups',
    location: 'dropdowns/location',
    feedType: 'dropdowns/{0}',
    FeedInventory: 'dropdowns/{0}',
    semen: 'dropdowns/semen',
    farmData: 'users/farms_by_user',
    groupData: 'dropdowns/groups',
    state: 'dropdowns/state',
    owner: 'dropdowns/farm_owner',
    geofenceName: 'dropdowns/geofence_names',
    certificateName: 'dropdowns/animal/{0}/certificate',
    ConversationLanguages: 'dropdowns/conversation_languages',
    animalGeoLocationUnmapped: 'dropdowns/animals/geolocation_unmapped',
    geoLocationDeviceUnmapped: 'dropdowns/geolocation_device_unmapped',
  },

  farmSystemParameter: {
    regionalSettings: 'farm_system_parameters/regional_settings',
    systemParameterList: 'farm_system_parameters/list',
    systemParameterEdit: 'farm_system_parameters/{0}',
  },

  settings: {
    users: {
      listUser: 'users',
      addUser: 'users/add',
      editUser: 'users/edit/{0}',
      getUser: 'users/{0}',
      deleteUser: 'users/{0}',
    },

    semen: {
      listSemen: 'semen/list',
      addSemen: 'semen',
      editSemen: 'semen/{0}',
      getSemen: 'semen/{0}',
      deleteSemen: 'semen/{0}',
    },

    medicalterms: {
      listMedicalterms: 'medical_terms/list/{0}',
      addMedicalterms: 'medical_terms',
      editMedicalterms: 'medical_terms/{0}',
      getMedicalterms: 'medical_terms/{0}',
      deleteMedicalterms: 'medical_terms/{0}',
    },

    groups: {
      listGroups: 'farm_animal_group/list',
      addGroups: 'farm_animal_group/add',
      GroupById: 'farm_animal_group/{0}',
      deleteGroup: 'farm_animal_group/{0}',
      csvUpload: 'farm_animal/import',
      thingsBoarddevice: 'thinkboard/devices_location',
      thingsBoardgeofence: 'thinkboard/geofence_data',
      csvExport: 'farm_animal/exportcsv/for_update',
      updatecsvUpload: 'farm_animal/import/update',
    },

    examination: {
      listexamination: 'farm_examination/list',
      addexamination: 'farm_examination',
      getexamination: 'farm_examination/{0}',
      editexamination: 'farm_examination/{0}',
      deleteexamination: 'farm_examination/{0}',
    },

    shades: {
      listShade: 'farm_shed/list',
      addShade: 'farm_shed/add',
      editShade: 'farm_shed/{0}',
      getShade: 'farm_shed/{0}',
      deleteShade: 'farm_shed/{0}',
    },
    breed: {
      listBreed: 'breed/list',
      addBreed: 'breed',
      editBreed: 'breed/{0}',
      getBreed: 'breed/{0}',
      deleteBreed: 'breed/{0}',
    },

    heat: {
      heatList: 'animal/{0}/heat_alert/list',
      heatAdd: 'animal/{0}/heat_alert',
      heatEdit: 'animal/{0}/heat_alert/list',
      heatDelete: 'animal/{0}/heat_alert/{1}',
      heatGet: 'animal/{0}/heat_alert/list',
      heatApprove: 'animal/{0}/heat_alert/{1}/approve',
      heatReject: 'animal/{0}/heat_alert/{1}/reject',
      heatWindow: 'animal/{0}/heat_alert/heat_window',
    },
  },

  livestock: {
    addNewAnimal: 'farm_animal/add',
    GetAllAnimalList: 'farm_animal/list',
    GetAnimalActivitySummary: 'farm_animal/activity-summary/{0}',
    bodyWeightList: 'farm_animal_weight/animal/{0}/list',
    bodyWeightAdd: 'farm_animal_weight/{0}/add',
    bodyWeightGet: 'farm_animal_weight/{0}',
    bodyWeightDelete: 'farm_animal_weight/{0}',
    bodyWeightEdit: 'farm_animal_weight/{0}/update/{1}',
    bodyScoreList: 'farm_animal_body_score/animal/{0}/list',
    bodyScoreAdd: 'farm_animal_body_score/{0}/add',
    bodyScoreGet: 'farm_animal_body_score/{0}',
    bodyScoreDelete: 'farm_animal_body_score/{0}',
    bodyScoreEdit: 'farm_animal_body_score/{0}/update/{1}',
    profile: 'farm_animal/{0}/edit_animal_picture',
    getAnimalDeviceList: 'farm_animal/animal-device',
    deleteAnimalDeviceList: 'farm_animal/animal-device/{0}',
    postAnimalDeviceList: 'farm_animal/animal-device',
    importactivityMonitor: 'farm_animal/import_device_mapping',
    bodyWeightAddMultiple: 'farm_animal_weight/add_multiple',
    heatAddMultiple: 'animal/{0}/heat_alert/bulkadd',
    animal: {
      getAnimalInDetails: 'farm_animal/{0}',
      editProfileOverview: 'farm_animal/{0}',
      changeGroup: 'farm_animal/{0}/group_update ',
      deleteAnimal: 'farm_animal/{0}',
      exportCsv:'farm_animal/exportcsv',
      greenCowExportCsv:'farm_animal/exportcsv/green_cow'
    },
    vaccinaction: {
      getlist: 'vaccination/animal/{0}/list',
      getvaccinebyid: 'vaccination/{0}/animals/{1}',
      deletevaccine: 'vaccination/animals/remove',
    },
    examination: {
      getExaminationByFarmAnimal: 'farm_animal/{0}/examination/{1}',
    },

    location: {
      addLocation: 'animals/{0}/location',
      LocationById: 'animals/{0}/location/{1}',
      LocationList: 'animals/{0}/location/list',
      deleteLocation: 'animals/{0}/location/{1}',
      updateLocation: 'animals/{0}/location/{1}',
    },

    milkYield: {
      list: 'farm_animal_milkyeild/list',
      add: 'farm_animal_milkyeild',
      edit: 'farm_animal_milkyeild/{0}',
      update: 'farm_animal_milkyeild/{0}',
      delete: 'farm_animal_milkyeild/{0}',
    },
  },

  healthManagement: {
    examination: {
      examinationList: 'farm_examination/{0}/animal/{1}/list',
      addexamination: 'farm_examination/animals',
      deleteanimalexamination: 'farm_examination/animals/remove',
      updateexamination: 'farm_animal/{0}/update_examination',
    },

    medicine: {
      medicinelist: 'medicine/animal/0/list',
      addmedicine: 'medicine/animals',
      deletemedicine: 'medicine/animals/remove',
    },

    vaccine: {
      vaccinelist: 'vaccination/animal/0/list',
      addvaccine: 'vaccination/animals',
      deletevaccine: 'vaccination/animals/remove',
      updatevaccine: 'vaccination/animals/{0}/update_vaccination',
    },
  },

  thinksboard: {
    timeSeries: 'thinkboard/Telemetery/timeseries/{0}',
    timeSeries_v2: 'thinkboard/Telemetery/timeseries/{0}/v2',
    activitiesGraph: 'farm_animal/{0}/activities_graph',
    devicelist: 'thinkboard/devicelist?pageSize={0}&page={1}',
    temperatureGraph: 'thinkboard/{0}/temperature_graphdata',
  },

  administration: {
    agenda: {
      addAgenda: 'farm_agenda',
      agendaList: 'farm_agenda/list',
      agendaById: 'farm_agenda/{0}',
      editAgenda: 'farm_agenda/{0}',
      deleteAgenda: 'farm_agenda/{0}',
    },

    incomeExpense: {
      addIncomeExpense: 'farm_income_expense',
      incomeExpenseList: 'farm_income_expense/list',
      incomeExpenseById: 'farm_income_expense/{0}',
      editIncomeExpense: 'farm_income_expense/{0}',
      deleteIncomeExpense: 'farm_income_expense/{0}',
    },
  },
  feed: {
    feedStock: {
      addFeedStock: 'farm_feed',
      FeedStockList: 'farm_feed/list',
      FeedStockById: 'farm_feed/{0}',
      editFeedStock: 'farm_feed/{0}',
      deleteFeedStock: 'farm_feed/{0}',
    },
    feedInventory: {
      addFeedInventory: 'farm_feed/{0}/feed_inventory',
      FeedInventoryList: 'farm_feed/{0}/feed_inventory/list',
      FeedInventoryById: 'farm_feed/{0}/feed_inventory/{1}',
      editFeedInventory: 'farm_feed/{0}/feed_inventory/{1}',
      deleteFeedInventory: 'farm_feed/{0}/feed_inventory/{1}',
    },
  },
  pregnancy: {
    addpregnancy: 'farm_animal_pregnancy/{0}',
    pregnancyList: 'farm_animal_pregnancy/{0}',
    pregnancyById: 'farm_animal_pregnancy/{0}/animal_pregnancy/{1}',
    editpregnancy: 'farm_animal_pregnancy/{0}/animal_pregnancy/{1}',
    deletepregnancy: 'farm_animal_pregnancy/{0}/animal_pregnancy/{1}',
  },
  Insemination: {
    addinsemination: 'animal/{0}/insemination',
    inseminationList: 'animal/{0}/insemination/list',
    inseminationById: 'animal/{0}/insemination/{1}',
    editinsemination: 'animal/{0}/insemination/{1}',
    deleteinsemination: 'animal/{0}/insemination/{1}',
  },

  animalMilkyeild: {
    addanimalMilkyeild: 'animal/farm_animal_milkyeild',
    animalMilkyeildList: 'animal/farm_animal_milkyeild/{0}/list',
    animalMilkyeildById: 'animal/farm_animal_milkyeild/{0}',
    editanimalMilkyeild: 'animal/farm_animal_milkyeild/{0}',
    deleteanimalMilkyeild: 'animal/farm_animal_milkyeild/{0}',
  },

  animal_Status: {
    list: 'AnimalStatusUpdate/animal-updates/{0}',
    update: 'AnimalStatusUpdate/animal-updates',
  },

  farm: {
    get_farm: 'farms',
    update_farm: 'farms',
  },

  dashaboard: {
    dashboard_count: 'dashboard',
    dashboard_graph: 'dashboard/graph',
  },

  reports: {
    animal: 'farm_animal/exportcsv',
    finance: 'farm_income_expense/exportcsv?type={0}',
    milk: 'animal/farm_animal_milkyeild/exportcsv?type={0}',
    insermination: 'animal/{0}/insemination/exportcsv',
    vaccsin: 'vaccination/{0}/exportcsv?type={1}',
    milkCummulativelist: 'animal/farm_animal_milkyeild/cummulative_list',
    reportFinance: 'farm_income_expense/cummulative_list',
    inserminationList: 'animal/{0}/insemination/list',
    vaccineList: 'vaccination/{0}/cummulative_list',
    examination: 'farm_examination/{0}/exportcsv?type={1}',
    examinationListCummulative: 'farm_examination/{0}/cummulative_list',
    treetmentCummulativelist: 'medicine/{0}/cummulative_list',
    treetment: 'medicine/{0}/exportcsv?type={1}',
    milkGraph:
      'animal/{0}/farm_animal_milkyeild/comparision?StartDate={1}&EndDate={2}',
    weightGraph:
      'farm_animal_weight/animal/{0}/comparision?StartDate={1}&EndDate={2}',
    // ?Startdate=2022-12-01&Enddate=2022-12-22
    inactiveDevice: 'farm_animal/exportcsv_ownerdetails',
  },

  adminuser: {
    adminusers: 'users/all/list',
  },

  services: {
    addServices: 'services',
    updateServices: 'services/{0}',
    getServices: 'services/{0}',
    listServices: 'services/list/{0}',
    deleteServices: 'services/{0}',
  },

  ownerregistration: {
    addownerregistration: 'owner',
    updateownerregistration: 'owner/{0}',
    getownerregistration: 'owner/{0}',
    listownerregistration: 'owner/list',
    deleteownerregistration: 'owner/{0}',
  },

  reportConfiguration: {
    emailReportsList: 'email_report/list',
    emailReportUpdate: 'email_report/update',
  },

  bulkimageupload: {
    multipleanimal: 'farm_animal/{0}/update_images',
    getmultiple: 'farm_animal/{0}/images',
    getmuzzleimages: 'farm_animal/{0}/muzzle_images',
    deleteAnimal: 'farm_animal/{0}/image/{1}',
  },

  farmoperation: {
    getFarmOperation: 'farm_operations/farm_operation_type/{0}',
    postFarmOperation: 'farm_operations/{0}',
    listGeofenceAlert: 'farms/geofence',
    notificationAckwledges: 'webhook/{0}',
    geoFenceListReport: 'Webhook/fence_out_animals/list',
    geoFenceReportGenerate: 'Webhook/fence_out_animals/export',
    heatAndHealthReportGenerate: 'Webhook/export_alerts',
    breaderReportList: 'farm_animal/list/repeat_breader',
    breaderReportExport: 'farm_animal/export_repeat_breader_report',
    infertilityReportList: 'farm_animal/list/infertility',
    infertilityReportExport: 'farm_animal/export_infertility_report',
  },

  youtube: {
    getYoutubeId: 'youtube',
  },

  animalCertificate: {
    addCertificate: 'animal/{0}/certificate',
    getCertificate: 'animal/{0}/certificate/list',
    deleteCertificate: 'animal/{0}/certificate/{1}',
  },
  file: {
    saveFiles: '/files',
  },

  conversation: {
    createConversion: 'conversation',
    updateConversion: 'conversation/{0}/message',
    updateConversion2: 'conversation/{0}/message/mod',
    getConversion: 'conversation/list',
  },

  login: {
    sendOtp: 'send_auth_code',
    sendotpChangeNumber: 'send_otp',
    changePhoneNumber: 'users/update_phone',
  },

  graph_json: {
    list: 'thinkboard/custom_graph/list',
    uploadCSV: 'thinkboard/graphcsvdata',
    delete: 'thinkboard/customgraph_data/{0}/animal/{1}',
  },

  geo_location_devices: {
    list: 'farm_animal/animal_geolocation_devices',
    addndelGeoLocationDevice: 'farm_animal/animal_geolocation_devices/{0}',
    geoLocationHistoryList:'farm_animal/{0}/geo_location_devices/history_list'
  },
};
